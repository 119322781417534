import React from 'react';
import {haptic} from "../pages/Go";
import logo from "../assets/logo_dry.svg";
import {motion} from "framer-motion";
import './Home.css';
import background from '../assets/twitter.svg';
import logo_ears from "../assets/logo_ears.svg";
import {Link} from "react-router-dom";

const Home = () => {
    return (
        <div className={'home-wrapper'}>

            <div className={'home-top'}>
                <h1 className={'white'}>
                    <span className={'purple'}>TONGO</span> <br/>NETWORK
                </h1>

                <p className={'white'}>
                    <span className={'purple'}>
                    TonGo is a decentralized social network
                    </span>
                    &nbsp;with integrated ad traffic, built on
                    <span className={'purple'}>
                        &nbsp;Telegram's infrastructure.
                    </span>
                </p>
            </div>

            <a href={'https://t.me/tongonetworkbot/tongo'} className={'home-btn-container'}>

                <motion.div
                    /*whileTap={{scale: 0.98}}
                    transition={{
                        duration: 0.025,
                        ease: [1, 0, 0, 1],
                    }}*/
                    className={'home go-button-wrapper'}
                >
                    <div className={'home go-button'}>
                        <img src={logo}/>
                        <img src={logo_ears}/>
                    </div>
                </motion.div>

            </a>



            <div className={'home-bottom'}>

                <div className={'home-bottom-top'}>

                    <div className={'home-bottom-top-left'}>
                        <a href="x.com">
                            <button className={'btn purple'}>
                                TWITTER
                            </button>
                        </a>

                        <a href="https://t.me/tongonetworkbot/tongo">
                            <button className={'btn white'}>
                                TELEGRAM
                            </button>
                        </a>
                    </div>

                    <a href="mailto:theodorekristina10@gmail.com">
                        <button className={'btn purple empty'}>
                            CONTACT
                        </button>
                    </a>
                </div>


                <Link to="/terms-of-use">
                    <p className={'purple'}>
                        Terms Of Use
                    </p>
                </Link>

            </div>


            <img src={background} className={'home-background-img'} />

        </div>
    );
};

export default Home;