import {BrowserRouter, Route, Routes, useLocation, useRoutes} from "react-router-dom";
import Go from "./pages/Go";
import Home from "./components/Home";
import {AnimatePresence} from "framer-motion";
import React from "react";
import {TonConnectUIProvider} from "@tonconnect/ui-react";
import Terms from "./components/Terms";

function App() {

    return (
        <TonConnectUIProvider
            manifestUrl="https://tongo.network/tonconnect-manifest.json"
            actionsConfiguration={{
                twaReturnUrl: 'https://t.me/tongonetworkbot/tongo'
            }}
        >
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/terms-of-use" element={<Terms />} />
                    <Route path="/go" element={<Go />} />
                </Routes>
            </BrowserRouter>
        </TonConnectUIProvider>
  );
}

export default App;
