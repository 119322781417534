import React, {useEffect, useState} from 'react';
import {first_name, haptic, is_premium, tg} from "../pages/Go";
import './Welcome.css';
import hi_illust from "../assets/hi_illust.svg";
import t1 from "../assets/tutorial/1.svg";
import t2 from "../assets/tutorial/2.svg";
import t3 from "../assets/tutorial/3.svg";
import t4 from "../assets/tutorial/4.svg";
import t5 from "../assets/tutorial/5.svg";
import welcome1 from "../assets/welcome1.png";
import welcome2 from "../assets/welcome2.png";
import welcome3 from "../assets/welcome3.png";

const dots = [1,2,3,4,5];

const Tutorial = ({tutorial, setTutorial}) => {

    const [tutorialStep, setTutorialStep] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setTutorialStep(0);
        }, 600)
    }, [tutorial])

    return (
        <div className={`${tutorial ? 'active' : ''} welcome-wrapper tutorial`}>



            <div
                className={'welcome-control-left'}
                onClick={() => {
                    haptic.impactOccurred('light');
                    if (tutorialStep > 0) {
                        setTutorialStep(prev => prev - 1);
                    } else {
                        setTutorial(false)
                    }
                }}
            >
                &nbsp;
            </div>

            <div
                className={'welcome-control-right'}
                onClick={() => {
                    haptic.impactOccurred('light');
                    if (tutorialStep < 4) {
                        setTutorialStep(prev => prev + 1)
                    } else {
                        setTutorial(false)
                    }
                }}
            >
                &nbsp;
            </div>


            <img src={t1} alt="" width={0} height={0}/>
            <img src={t2} alt="" width={0} height={0}/>
            <img src={t3} alt="" width={0} height={0}/>
            <img src={t4} alt="" width={0} height={0}/>
            <img src={t5} alt="" width={0} height={0}/>

            <img
                src={
                    tutorialStep === 0 ?
                        t1
                        :
                        tutorialStep === 1 ?
                            t2
                            :
                            tutorialStep === 2 ?
                                t3
                                :
                                tutorialStep === 3 ?
                                    t4
                                    :
                                    tutorialStep === 4 ?
                                        t5
                                        :
                                        t5
                }
                key={
                    tutorialStep === 0 ?
                        t1
                        :
                        tutorialStep === 1 ?
                            t2
                            :
                            tutorialStep === 2 ?
                                t3
                                :
                                tutorialStep === 3 ?
                                    t4
                                    :
                                    tutorialStep === 4 ?
                                        t5
                                        :
                                        t5
                }
                alt="hello"
            />

            <div className={'welcome-top-dots-container'} style={{gridTemplateColumns: 'repeat(5,1fr)'}}>
                {
                    dots.map((item, index) =>
                        <div className={`${tutorialStep >= index ? 'active' : ''} welcome-top-dot`} key={index}>

                        </div>
                    )
                }
            </div>


            <div className={'welcome-center-container'}>

                <h2 className={'lactos purple'}>
                    {
                        tutorialStep === 0 ?
                            <>
                                Post a story
                                <br/>
                                and claim GO
                            </>
                            :
                            tutorialStep === 1 ?
                                <>
                                    Grow your
                                    <br/>
                                    own network
                                </>
                                :
                                tutorialStep === 2 ?
                                    <>
                                        Use GO Boost
                                        <br/>
                                        to Up GO Power
                                    </>
                                    :
                                    tutorialStep === 3 ?
                                        <>
                                            Get Allocation
                                            <br/>
                                            of TonGO
                                        </>
                                        :
                                        tutorialStep === 4 ?
                                            <>
                                                Become a member
                                                <br/>
                                                of the Network
                                            </>
                                            :
                                            ''
                    }

                </h2>

                <p className={'white'}>
                    {
                        tutorialStep === 0 ?
                            <>
                                If you are <span className={'purple'}> Premium User </span> you can
                                <br/>
                                claim GO by <span className={'purple'}> Posting GO Story </span>
                                {/*<span className={'purple'}>Welcome to TonGO!</span>*/}
                            </>
                            :
                            tutorialStep === 1 ?
                                <>
                                    <span className={'purple'}> Invite Friends </span> and get
                                    <br/>
                                    <span className={'purple'}> 30% </span> of Their GO Income
                                </>
                                :
                                tutorialStep === 2 ?
                                    <>
                                        You can use <span className={'purple'}> STARS </span> or <span className={'purple'}> TON </span>
                                        <br/>
                                        to buy GO Power
                                    </>
                                    :
                                    tutorialStep === 3 ?
                                        <>
                                            Receive <span className={'purple'}> $TONGO </span> based
                                            <br/>
                                            on your <span className={'purple'}> GO and GO Power </span>
                                        </>
                                        :
                                        tutorialStep === 4 ?
                                            <>
                                                Take part in the biggest
                                                <br/>
                                                <span className={'purple'}> Global Social Media Network </span>
                                            </>
                                            :
                                            ''
                    }
                </p>

            </div>


            <button
                className={`bottom full btn ${tutorialStep === 2 && true === 0 ? 'purple' : 'white'}`}
                onClick={() => {
                    haptic.impactOccurred('light');
                    if (tutorialStep === 4) {
                        if (true !== 0) {
                            setTutorial(false);
                        } else {
                            tg.openTelegramLink('https://t.me/tongonetwork')
                        }
                    } else {
                        setTutorialStep(prev => prev + 1);
                    }
                }}
            >
                {
                    tutorialStep === 0 ?
                        "GO AHEAD"
                        :
                        tutorialStep === 1 ?
                            "AWESOME"
                            :
                            tutorialStep === 2 ?
                                "COOL"
                                :
                                tutorialStep === 3 ?
                                    "AMAZING"
                                    :
                                    tutorialStep === 4 ?
                                        "INCREDIBLE"
                                        :
                                        tutorialStep === 5 ?
                                            "CONTINUE"
                                            :
                                            "OK"

                }
            </button>

        </div>
    );
};

export default Tutorial;