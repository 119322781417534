import React from 'react';
import {haptic} from "../pages/Go";
import logo from "../assets/logo_dry.svg";
import {motion} from "framer-motion";
import './Home.css';
import background from '../assets/twitter.svg';
import logo_ears from "../assets/logo_ears.svg";

const Terms = () => {
    return (
        <div className={'home-wrapper'}>

            <div className={'home-top'}>
                <h1 className={'white'}>
                    <span className={'purple'}>TERMS</span> <br/>OF USE
                </h1>

                <p className={'white'}>
                    <span className={'purple'}>
                    TonGo is a decentralized social network
                    </span>
                    &nbsp;with integrated ad traffic, built on
                    <span className={'purple'}>
                        &nbsp;Telegram's infrastructure.
                    </span>
                </p>
            </div>

            <div className={'terms-container'}>

                <h2 className={'purple'}>
                    BACKGROUND
                </h2>

                <p className={'white'}>
                    TonGo is a game that rewards players with tokens for their in-game activities.
                </p>


                <h3>
                    1. ACCEPTANCE OF THESE TERMS AND CONDITIONS
                </h3>
                <p className={'white'}>
                    Our game is accessible only upon your acceptance of these terms and conditions. By playing the game, you confirm that you have read, understood, and agreed to be bound by these terms and conditions in their entirety. If you do not agree to or accept all the terms and conditions, you are expressly prohibited from using our services and must cease all use immediately.
                </p>

                <h3>
                    2. CHANGES TO THESE TERMS AND CONDITIONS
                </h3>
                <p className={'white'}>
                    Supplemental terms, conditions, or documents that we may post from time to time are hereby incorporated into these Terms and Conditions by reference. We reserve the right, at our sole discretion, to modify or update these Terms and Conditions at any time for any reason. Notification of changes will be provided by updating the 'Last Updated' date at the top of these Terms and Conditions. You acknowledge and agree that you waive any right to receive specific notice of each modification.
                    <br/>
                    It is your responsibility to periodically review these Terms and Conditions to remain informed of updates. Your continued use of TonGo after the posting of revised Terms and Conditions constitutes your acknowledgment and acceptance of those changes.
                </p>

                <h3>
                    3. APPLICATION OF THESE TERMS AND CONDITIONS
                </h3>
                <p className={'white'}>
                    These Terms and Conditions apply to all participants of the TonGo game, including individuals, merchants, and legal entities.
                </p>

                <h3>
                    4. GAMEPLAY
                </h3>
                <p className={'white'}>
                    You understand and agree that the gameplay is intended solely for recreational and entertainment purposes. Any tokens earned through the game are not intended to hold monetary value and are solely designed to enhance gameplay functionality.
                </p>

                <h3>
                    5. IN-GAME PURCHASES
                </h3>
                <p className={'white'}>
                    You acknowledge that TonGo may offer optional in-game assets for purchase. These purchases are not required to play the game and are made entirely at the player’s discretion. TonGo assumes no responsibility for these in-game assets, and players are solely responsible for their decisions regarding such purchases. Payments for in-game assets may be made using cryptocurrencies on supported blockchains or Stars, the payment currency of Telegram.
                </p>

                <h3>
                    6. INTELLECTUAL PROPERTY RIGHTS
                </h3>
                <p className={'white'}>
                    You acknowledge and agree that TonGo retains all rights, title, and interest in and to all copyrights, trademarks, trade secrets, patents, and other proprietary rights associated with TonGo, its airdrops, and all related content. You further acknowledge that 'TonGo,' including its trademarks, service marks, logos, and graphics, are registered trademarks or trademarks owned by TonGo.
                    <br/>
                    You are not permitted to create, distribute, or sell fan art (digital or physical), merchandise, or similar content based on TonGo, its affiliated projects, or any of its associated intellectual property for commercial purposes.
                </p>

                <h3>
                    7. DATA PROTECTION
                </h3>
                <p className={'white'}>
                    In order to provide you with access to TonGo, you acknowledge and consent to the collection, storage, and processing of your personal data and/or information as necessary.
                </p>

                <h3>
                    8. USER REPRESENTATIONS
                </h3>
                <p className={'white'}>
                    You acknowledge and agree that TonGo does not provide financial advice, guidance, or recommendations regarding the purchase, sale, or holding of any currency, asset, or investment opportunity. If you are considering purchasing any asset as an investment, you should consult a qualified financial advisor for appropriate advice and guidance.
                    <br/>
                    By participating in TonGo, you warrant that you are a bona fide participant and confirm that you will not use TonGo’s services for any prohibited activities or purposes, including but not limited to:
                </p>

                <p className={'white'}>
                    (a) Financing or supporting terrorism;<br/>
                    (b) Engaging in money laundering activities;<br/>
                    (c) Participating in illegal gambling operations;<br/>
                    (d) Distributing or funding illegal drugs or drug paraphernalia;<br/>
                    (e) Engaging in malicious hacking, including activities related to ransomware payments;<br/>
                    (f) Conducting business activities that present elevated financial risk, including pyramid schemes, network marketing, or referral marketing programs;<br/>
                    (g) Providing knowingly inaccurate or incomplete information to TonGo;<br/>
                    (h) Reverse-engineering, disassembling, or attempting to replicate TonGo’s source code, formulas, or processes;<br/>
                    (i) Compromising the security of TonGo or interfering with its safe use by others, such as distributing viruses, corrupted files, or malicious software;<br/>
                    (j) Using TonGo’s services for unlawful purposes or in a manner that infringes upon intellectual property rights or other legal rights of any party;<br/>
                    (k) Exploiting technical errors, loopholes, or glitches within TonGo’s airdrop or services for personal gain;<br/>
                    (l) Utilizing TonGo’s platform or information in a way that is competitive to TonGo’s business or detrimental to its interests;<br/>
                    (m) Employing systematic or repetitive methods to extract data or overload TonGo’s infrastructure;<br/>
                    (n) Publishing, disseminating, or engaging in communications that are offensive, false, unlawful, defamatory, indecent, or otherwise inappropriate, including spam or deliberately misleading content;<br/>
                    (o) Collecting or storing personal information about other users without authorization; or<br/>
                    (p) Performing any actions that interfere with or negatively affect the operation of TonGo’s airdrop, services, or user experience.<br/>
                    <br/>
                    By using TonGo, you agree to abide by these conditions and acknowledge that any breach may result in the termination of your access to TonGo’s services.
                </p>


                <h3>
                    9. PROHIBITED ACTIVITES
                </h3>
                <p className={'white'}>
                    You may not access or use TonGo for any purpose other than as provided for by us. The use of TonGo for commercial endeavors is strictly prohibited unless expressly authorized in a binding legal agreement with TonGo.
                    <br/>
                    The following activities are prohibited in relation to TonGo, including but not limited to:
                </p>

                <p className={'white'}>
                    - Systematically retrieving data or other content from TonGo to create or compile a collection, compilation, database, or directory without our explicit written consent;<br/>
                    - Using TonGo in unauthorized ways, such as collecting usernames or email addresses for unsolicited communications, or creating user accounts via automated means or under false pretenses;<br/>
                    - Employing buying or purchasing agents to make transactions on TonGo;<br/>
                    - Advertising or offering to sell goods or services through TonGo;<br/>
                    - Circumventing, disabling, or interfering with security-related features of TonGo, including measures that restrict or prevent unauthorized use or copying of content;<br/>
                    - Engaging in unauthorized framing of or linking to TonGo;<br/>
                    - Defrauding or misleading us or other users, particularly in attempts to access sensitive account information;<br/>
                    - Improperly using our support services or submitting false abuse or misconduct reports;<br/>
                    - Engaging in automated use of the system, such as using scripts, bots, or similar tools to gather data or interact with TonGo;<br/>
                    - Disrupting or creating an undue burden on TonGo's infrastructure, networks, or connected services;<br/>
                    - Impersonating another user or using another user’s credentials;<br/>
                    - Selling or transferring your user profile to another party;<br/>
                    - Using information obtained from TonGo to harass, abuse, or harm others;<br/>
                    - Using TonGo or its content to compete with us or for unauthorized commercial or revenue-generating purposes;<br/>
                    - Deciphering, decompiling, disassembling, or reverse-engineering any of TonGo’s software or systems;<br/>
                    - Attempting to bypass access controls or restrictions implemented on TonGo;<br/>
                    - Harassing, intimidating, or threatening our employees or agents;<br/>
                    - Removing copyright or proprietary notices from any TonGo content;<br/>
                    - Copying or adapting TonGo's software, including HTML, JavaScript, or other code, without authorization;<br/>
                    - Uploading or transmitting viruses, Trojan horses, or other harmful materials, or engaging in spamming or excessive use of disruptive features like capital letters;<br/>
                    - Uploading or transmitting passive or active data collection mechanisms, such as spyware, web bugs, or cookies, without authorization;<br/>
                    - Launching or distributing automated systems, including spiders, bots, or cheat utilities, to interact with TonGo, except for standard search engine or browser operations;<br/>
                    - Disparaging, tarnishing, or otherwise harming TonGo, its reputation, or its operations, as judged solely at our discretion;<br/>
                    - Using TonGo as a minor (under 18 years of age);<br/>
                    - Engaging in activities inconsistent with applicable laws and regulations;<br/>
                    - Any violation of these terms may result in the suspension or termination of your access to TonGo and further legal action if necessary;<br/>
                </p>


                <h3>
                    10. TAXES
                </h3>
                <p className={'white'}>
                    You are solely responsible for determining, collecting, reporting, and remitting any taxes applicable to payments made or received through TonGo. It is your obligation to comply with all relevant tax laws and regulations. You acknowledge and agree that TonGo does not currently provide taxation reports for transactions conducted through the platform. Therefore, it is your responsibility to gather and maintain the necessary information to meet the requirements of the appropriate tax authorities.
                </p>

                <h3>
                    11. THIRD PARTY WEBSITES AND CONTENT
                </h3>
                <p className={'white'}>
                    TonGo may include links to third-party websites (“Third-Party Websites”) or display content, materials, or applications originating from third parties (“Third-Party Content”), such as articles, photographs, graphics, designs, music, videos, software, and other items. These Third-Party Websites and Third-Party Content are not monitored, reviewed, or verified by us for accuracy, completeness, or appropriateness, and we make no guarantees regarding their quality or reliability.
                    <br/>
                    Accessing Third-Party Websites or interacting with Third-Party Content through TonGo is done at your own discretion and risk. Such inclusion or linking does not imply endorsement or approval by TonGo. Once you navigate away from TonGo to access Third-Party Websites or use Third-Party Content, these Terms and Conditions no longer apply. We strongly recommend reviewing the applicable terms of use, privacy policies, and other relevant documentation of those third parties to understand their practices.
                    <br/>
                    TonGo is not responsible for any transactions conducted through Third-Party Websites. Any purchases made on these platforms are strictly between you and the respective third party, and we disclaim any responsibility for such transactions, including the products or services purchased. You acknowledge and agree that we do not endorse or warrant the products, services, or practices of any Third-Party Websites and accept no liability for any damages, losses, or harm you may incur as a result of interacting with Third-Party Websites or Content, including through purchases, data collection, or any other engagement.
                </p>

                <h3>
                    12. LIMITATION OF LIABILITY
                </h3>
                <p className={'white'}>
                    To the fullest extent permitted by law, TonGo, its officers, shareholders, employees, agents, directors, subsidiaries, affiliates, successors, assigns, suppliers, and licensors shall not be liable for any loss or damages of any kind arising out of or in connection with your use of TonGo. This includes, without limitation, direct, indirect, incidental, special, consequential, or punitive damages, regardless of the legal theory under which the claim arises (contract, tort, or otherwise), and irrespective of whether such damages were foreseeable or TonGo was advised of the possibility of such damages.
                    Without limiting the foregoing, TonGo is not liable for any financial or other losses or damages arising from:
                </p>

                <p className={'white'}>
                    - Delays, interruptions, or loss of services.
                    - Technical failures, malfunctions, or system shutdowns.
                    - Server failures, hacks, or unavailability.
                    - Data loss or corruption on TonGo’s servers.
                    - Failure to provide accurate or updated information.
                    - Phishing attempts or websites fraudulently impersonating TonGo.
                    - Unauthorized access to or use of your account or authentication credentials.
                    - Loss of business opportunities, goodwill, or reputation.
                    - These limitations apply even if TonGo has been advised of the possibility of such damages.
                </p>

                <h3>
                    13. EXCLUSION OF LIABILITY FOR THIRD-PART SERVICES
                </h3>
                <p className={'white'}>
                    TonGo may integrate with or support third-party services. TonGo is not responsible for any actions, errors, or omissions by third-party services and disclaims any liability for losses or damages resulting from their use.
                </p>

                <h3>
                    14. DISCLAIMER OF WARRANTIES
                </h3>
                <p className={'white'}>
                    TonGo is provided on an “as is” and “as available” basis, without any representations or warranties of any kind, whether express or implied, to the maximum extent allowed by law. TonGo expressly disclaims any implied warranties of title, merchantability, fitness for a particular purpose, and non-infringement.
                </p>

                <h3>
                    15. INDEMNIFICATION
                </h3>
                <p className={'white'}>
                    To the fullest extent permitted by applicable laws, you agree to indemnify, defend, and hold harmless TonGo, its officers, shareholders, employees, agents, directors, subsidiaries, affiliates, successors, assigns, suppliers, and licensors from and against any and all third-party claims, liabilities, damages, losses, costs, and expenses (including reasonable attorney fees) arising from or related to:
                </p>
                <p className={'white'}>
                    (a) Your actual or alleged breach of these Terms, including your representations and warranties;
                    (b) Your actual or alleged misuse of the Services; and
                    (c) Your actual or alleged infringement or violation of any applicable laws or the rights of any third party.
                </p>

                <h3>
                    16. ENTIRE AGREEMENT
                </h3>
                <p className={'white'}>
                    These Terms represent the entire agreement between you and TonGo concerning the subject matter herein and supersede all prior agreements, communications, and understandings, whether written or oral.
                </p>


                <h3>
                    17. SEVERABILITY AND WAIVER
                </h3>
                <p className={'white'}>
                    If any provision of these Terms is found to be invalid or unenforceable, that provision shall be enforced to the maximum extent permissible under applicable laws, and the remaining provisions shall remain in full force and effect.
                    <br/>
                    The failure of TonGo to enforce any provision or exercise any right under these Terms shall not be construed as a waiver of its rights to enforce such provision or exercise such right in the future.
                </p>


                <h3>
                    18. ASSIGNMENT
                </h3>
                <p className={'white'}>
                    TonGo may assign these Terms or delegate any of its obligations hereunder, in whole or in part, without prior notice to you. You may not assign or transfer these Terms, or any rights or obligations hereunder, to any third party without TonGo's prior written consent.
                </p>


                <h3>
                    19. NO PARTNERSHIP
                </h3>
                <p className={'white'}>
                    Nothing in these Terms shall be interpreted to create a partnership, joint venture, agency relationship, or fiduciary relationship between you and TonGo.
                </p>


                <h3>
                    20. FORCE MAJEURE
                </h3>
                <p className={'white'}>
                    TonGo shall not be liable for any delay or failure to perform its obligations under these Terms due to events beyond its reasonable control, including but not limited to acts of God, war, insurrection, bank failures, strikes, fires, floods, earthquakes, labor disputes, epidemics, governmental regulations, freight embargoes, or any other similar events.
                </p>


                <h3>
                    21. GOVERNING LAW
                </h3>
                <p className={'white'} style={{marginBottom: '0'}}>
                    These Terms, and your use of TonGo, shall be governed by and construed in accordance with the laws of the British Virgin Islands. Any disputes arising from or related to these Terms shall be resolved exclusively under the jurisdiction of the courts of the British Virgin Islands, without regard to its conflicts of law principles.
                </p>

            </div>



            <div className={'home-bottom'}>

                <div className={'home-bottom-top'}>

                    <div className={'home-bottom-top-left'}>
                        <a href="x.com">
                            <button className={'btn purple'}>
                                TWITTER
                            </button>
                        </a>

                        <a href="https://t.me/tongonetworkbot/tongo">
                            <button className={'btn white'}>
                                TELEGRAM
                            </button>
                        </a>
                    </div>

                    <a href="mailto:theodorekristina10@gmail.com">
                        <button className={'btn purple empty'}>
                            CONTACT
                        </button>
                    </a>
                </div>


                <a href="/">
                    <p className={'purple'}>
                        Back Home
                    </p>
                </a>

            </div>


            <img src={background} className={'home-background-img'} />

        </div>
    );
};

export default Terms;